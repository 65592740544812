@import "theme";
//922尺寸以下
@media (max-width: $screen-sm-max) {
  .header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: #e30000;
    z-index: 999;
  }

  .logo-box {
    display: block;
    width: 100px;
    margin: 5px 0  0 15px;
    .WLogo{display: block}
    .BLogo{display: none}

  }

  .nav-trigger {
    display: block;
    position: fixed;
    right: 15px;
    top: 13px;
    width: 32px;
    height: 32px;
    background: url('../images/menu.png') no-repeat center;
    background-size: 32px 32px;

    &.active {
      background: url('../images/menu.png') no-repeat center;
      background-size: 32px 32px;
    }
  }

  .nav-box {
    //position: fixed;
    //top: 60px;
    //left: 0;
    width: 100%;
    height: 0;
    color: #fff;
    overflow: hidden;
    background-color: #e30000;

    &.active {
      height: calc(100vh - 60px);
      overflow-y: auto;
      z-index: 999;

      .nav-list {
        padding: 0 15px;
        white-space: nowrap;
      }

      .nav-item-title {
        padding: 15px;
        font-size: 18px;
      }

      .nav-item-title-link {
        display: block;
        padding: 0;
        font-size: 18px;
        color:#fff;
        @media (min-width: $screen-md-min) {
          padding: 15px;
        }

      }

      .nav-item {
        position: relative;
        text-align: center;

        &:after {
          content: '';
          position: absolute;
          bottom: 1px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: rgba(255, 255, 255, .7);
          transform: scaleY(.5);
        }

        .nav-sub-list {
          width: 100%;
          height: 0;
          font-size: 18px;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.active {
          .nav-sub-list {
            height: auto;
          }

          .nav-sub-item {
            display: block;
            padding: 10px;
            color: #fff;
            text-decoration: none;
            outline: none;
            font-size: 16px;

            &:link,
            &:visited,
            &:hover,
            &:active {
              text-decoration: none;
              outline: none;
              color: #fff;
            }
          }

        }
      }

      .nav-search {
        padding: 15px;
        font-size: 24px;
        text-align: center;

        &:after {
          content: '';
          position: absolute;
          bottom: 1px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: rgba(255, 255, 255, .7);
          transform: scaleY(.5);
        }

      }

    }
  }
  .searchInput {
    display: none;
  }
}
//922尺寸以上
@media (min-width: $screen-md-min) {
  .header {
    display: flex;
    align-items: center;
    height: 120px;
    padding: 0 15px;
    background-color: #f7f8f8;
  }
  .logo-box {
    width: 139px;
    height: 62px;
    overflow: hidden;
    .WLogo{display: none}
    .BLogo{display: block}
  }
  .nav-box {
    flex: 1;
    display: flex;
    justify-content: center;
    z-index: 100;
    width: 100px;
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
  }
  .nav-trigger {
    display: none;
  }
  .nav-list {
    display: flex;
    align-items: center;
  }
  .nav-item {
    position: relative;
    padding-left: 5px;
    margin-right: 25px;
    cursor: pointer;
    &:hover {
      .nav-sub-list {
        position: absolute;
        top: 100%;
        left: 0;
        display: block;
        width: 150px;
        border-top: 10px solid transparent;
        border-radius: 5px;
        &:before {
          content: '';
          position: absolute;
          margin-top: -2px;
          margin-left: 10px;
          border-top: 2px solid #e0e0e0;
          border-left: 2px solid #e0e0e0;
          border-bottom: 2px solid transparent;
          border-right: 2px solid transparent;
          transform: rotate(45deg);
        }
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: .2em;
      left: -5px;
      display: block;
      height: 1em;
      width: 1px;
      background-color: #fff;
    }
  }

  .subpage-header {
    .nav-item:hover .nav-sub-list:before {

      border-top: 2px solid #e0e0e0;
      border-left: 2px solid #e0e0e0;
      border-bottom: 2px solid transparent;
      border-right: 2px solid transparent;
    }
    .nav-sub-item {
      background-color: #e0e0e0;
      &:hover {
        background-color: #e4e4e4;
      }
    }
  }

  .nav-sub-list {
    display: none;
  }
  .nav-sub-item {
    display: block;
    width: 100%;
    padding: 10px 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #333;
    background-color: #e0e0e0;
    font-size: 14px;
    &:hover {
      background-color: #e4e4e4;
    }

    text-decoration: none;
    outline: none;

    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
      outline: none;
      color: #333;
    }
  }
  .nav-search {
    position: relative;
    margin-top: 25px;
  }
  .nav-search-btn {
    position: relative;
    display: block;
    float: left;
    padding: 6px;
    margin-left: 20px;

    border-radius: 50%;
    &.blackBorder {
      border: 1px solid #666;
    }
    &.whiteBorder {
      border: 1px solid #fff;
    }
    &:before {
      content: '';
      position: absolute;
      top: .2em;
      left: -5px;
      display: block;
      //width: 2px;
      //height: 1em;
      //background-color: #fff;
    }

    &:after {
      content: '';
      position: absolute;
      top: .2em;
      right: -5px;
      display: block;
      //width: 2px;
      //height: 1em;
      //background-color: #fff;
    }
  }

  .searchInput {
    width: 50%;
    position: absolute;
    top: 50px;
    left: 25%;
    height: 100px;
    z-index: 100;
    &.searchNone{display: none}
    .inputIdx {
      width: 100%;
      height: 45px;
      border: 0;
      border-radius: 6px;
      background-color: #e5e5e5;
      padding: 0 10px;
      outline: none;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.6);
      &::placeholder {
        color: #b4b4b4
      }
    }
    .input{
      width: 100%;
      height: 45px;
      //border: 0;
      border-radius: 6px;
      background-color: #fff;
      padding: 0 10px;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
      //box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
      &::placeholder {
        color: #b4b4b4
      }
    }
  }
  .searchBtn {
    display: inline-block;
    background: url(../images/ico_searchG.png) no-repeat;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 8px;
    right: 10px;
    z-index: 200;
    cursor: pointer;
  }

  .subpage-header {
    .nav-box {
      color: #333;
    }
    .nav-item:before,
    .nav-search-btn:before,
    .nav-search-btn:after {
      background-color: #333;
    }
  }
}

@media (min-width: $screen-lg-min) {
  .header {
    padding: 0 45px;
  }

  .logo-box {
    width: 160px;
    height: 72px;
    margin-left: 30px;
  }

  .nav-box {
    font-size: 15px;
  }

  .nav-item {
    margin-right: 45px;
  }
}

@media (min-width: $screen-xl-min) {
  .header {
    padding: 0 45px;
  }
  //.nav-box {
  //  font-size: 16px;
  //}

  .logo-box {
    width: 160px;
    height: 72px;
    margin-left: 30px;
  }

}

.logo {
  width: 100%;
}

.nav-search-btn {
  text-decoration: none;
  outline: none;

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    outline: none;
  }
}

.nav-item-title-link {
  color: #fff;
  text-decoration: none;
  outline: none;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: #fff;
    text-decoration: none;
    outline: none;
  }
}

.subpage-header {
  .nav-item-title-link {
    color: #fff;
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: #fff;
    }
    @media (min-width: $screen-md-min) {
      color: #333;

      &:link,
      &:visited,
      &:hover,
      &:active {
        color: #333;
      }
    }

  }
}

.nav-search-icon {
  width: 1.4em;
  height: 1.4em;

}

.openQRCode {
  position: absolute;
  top: 25px;
  left: 8px;
  opacity: 0;
  transition: 1s;
  &:before {
    content: "";
    display: block;
    position: relative;
    left: 75px;
    width: 0;
    height: 0;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent #fff;
  }
  img {
    width: 160px;
  }

}

.wechat:hover + .openQRCode {
  top: 35px;
  opacity: 1;
}