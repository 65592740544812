//careerDev.html
.careerDev {
  margin-top: 0;
  @media (min-width: $screen-md-min) {
    margin-top: 100px;
  }
}

.job-hd {
  font-size: 18px;
  line-height: 54px;
  height: 54px;
  color: #b0b1b1;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  margin-top: -1px;
  text-align: center;
  font-weight: 300;
  transition: height 1.5s ease;
  overflow: hidden;
  cursor: pointer;
  @media (min-width: $screen-md-min) {
    font-size: 30px;
    line-height: 75px;
    height: 75px;
  }
}

.job-bd-pre {
  height: 0;
  overflow: hidden;
  transition: height 1.5s ease;
}

.job-bd {
  padding: 0 30px;
  position: relative;
  // height: 706px;
  display: flex;
  align-items: left;
  flex-direction: column;
  font-size: 14px;
  color: #fff;
  background-color: #a91d16;
  @media (min-width: $screen-sm-min) {
    align-items: center;
  }
}

.job-bd-hd {
  padding: 30px 0 30px;
  font-size: 28px;
  font-weight: 400;
  @media (min-width: $screen-sm-min) {
    font-size: 50px;
    padding: 80px 0 30px;
  }
}

.job-bd-item {
  width: 100%;
  text-align: left;
  overflow: hidden;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  cursor: pointer;
  @media (min-width: $screen-sm-min) {
    text-align: center;
  }
}

.job-bd-foot {
  // position: absolute;
  // left: 0;
  // right: 0;
  // bottom: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 12px;
  text-align: center;

  .workAddr {
    text-align: left;
    //width: 680px;
    margin: 0 auto;
    //padding-left: 30px;
    //position: relative;
    font-weight: 300;
    a {
      text-decoration: none;
      color: #fff;
      &:hover {
        text-decoration: underline;
      }
    }
    .workAddrTit {
      display: none;
    }
    b, span {
      display: block;
      margin-right: 0;
    }

    @media (min-width: $screen-sm-min) {
      padding-left: 65px;
      .workAddrTit {
        display: block;
        margin-bottom: 10px;
        font-weight: 400;
        //position: absolute;
        //left: 0;
      }
      span, b {
        display: inline-block;
        margin-right: 20px;
      }
      span:last-child {
        margin-right: 0
      }
    }
  }
}

// .job-bd-show {
//   height: 706px;
//   @media (min-width: $screen-sm-min) {
//     height: 506px;
//   }
// }

// production-details page
.production-details {
  background-color: #f7f8f8;
  .production-top-line {
    height: 0;
    border: 2px solid #e4e4e4;
  }
  .rate-line::before {
    float: left;
    content: '';
    width: 140px;
    transform: translateY(-2px);
    border: 2px solid red;
  }
  .rate-line::after {
    float: right;
    transform: translateY(-2px);
    content: '';
    width: 36px;
    height: 54px;
    margin-right: 15%;
    background: url(../images/book_tips.png) no-repeat left top;
    background-size: 100%;
  }
}

// company-history page

.history-content {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  margin: 0 auto;

  .history-hd {
    font-size: 16px;
    display: block;
    text-align: center;
    font-weight: 400;
  }
  .history-bd {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    .history-bd-m {
      position: relative;
      width: 0;
      margin: 0 15px;
      border-left: 2px solid #cd3a2a;
      .circle-top {
        position: absolute;
        top: -12px;
        left: -9px;
        width: 16px;
      }
      .circle-bottom {
        position: absolute;
        bottom: -12px;
        left: -9px;
        width: 16px;
      }
    }
    .history-item {
      position: relative;
      margin-top: 30px;
      .content-img {
        width: 100%;
      }
    }
    .history-bd-l {
      width: 600px;
      .history-item:first-child {
        margin-top: 15px;
      }
      .history-item > .circle-mark {
        position: absolute;
        top: -1px;
        right: -21px;
        width: 10px;
      }
    }
    .history-bd-r {
      width: 600px;
      .history-item:first-child {
        margin-top: 68px;
      }
      .history-item > .circle-mark {
        position: absolute;
        top: -1px;
        left: -21px;
        width: 10px;
      }
    }
  }
}

// addressCont page
.addressCont {
  background: url('../images/WechatIMG104.png') no-repeat center;
  background-size: 160% auto;
  padding: 60px 0 60px 0;
  background-color: #bdbdbd;
  @media (min-width: $screen-sm-min) {
    background-size: 80% auto;
  }
  @media (min-width: $screen-md-min) {
    background-size: 35% auto;
  }
}

.addressDetail {
  padding: 140px 20px 140px 20px;
  .addresstit {
    h2 {
      color: #c40e0e;
      font-size: 20px;
      margin-bottom: 20px
    }
    p {
      font-size: 16px;
      color: #ffffff;
      font-weight: 300;
    }
  }
  .addressMap {
    text-align: center;
    padding-top: 20px;
    img {
      width: 100%
    }
  }
  @media (min-width: $screen-sm-min) {
    .addresstit {
      h2 {
        font-size: 22px;
      }
    }
    .addressMap {
      img {
        width: 80%
      }
    }
  }
  @media (min-width: $screen-md-min) {
    padding: 140px 0 140px 0;
    .addresstit {
      h2 {
        font-size: 24px;
      }
    }
    .addressMap {
      img {
        width: 70%
      }
    }
  }
}



.addressh1 {
  h1 {
    font-size: 30px;
    color: #c40e0e;
    font-weight: 400;
    margin-left: 15px;
    margin-top: 50px;
  }
  @media (min-width: $screen-md-min) {
    h1 {
      margin-top: 0;
    }
  }

}

.addressCont {

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    outline: none;
    text-decoration: none;
    color: #333;
  }
  .subtitle {
    font-size: 16px;
    font-weight: 700;
    color: #cd3a2a;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .sub-company {
    color: #fff;
  }
  .subtitle {
    padding-top: 5px;
  }
  .company-item-pre {
    padding: 0;
    &:nth-child(3n) .company-item::after {
      border: none;
    }
    .company-item {
      position: relative;
      padding: 20px 15px;
      display: block;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      &:active {
        opacity: .5;
      }
      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        content: '';
        height: 70%;
        width: 0;
        border-right: 1px solid #fff;
      }
      .company-bd {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 12px;
        color: #fff;
        p {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.new-content {
  .new-item {
    .new-left {
      .new-hd {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        a {
          color: #df0623;
        }
      }
    }
  }
}

// 小于768
@media (max-width: $screen-sm-max) {
  .wrapper {
    padding-top: 60px;
  }
  .wrapper.addressDetail {
    padding-top: 120px;
    padding-bottom: 80px;
  }
  .addressCont {
    .company-item::after {
      border: none !important;
    }
  }
  .history-content {
    padding-top: 0;
  }
  // new-page

  .new-content {
    .new-item {
      .new-left {
        h2 {
          margin-bottom: 10px;
          font-size: 20px;
        }
        .circle-tips {
          display: none;
          width: 20px;
        }
      }
      .new-right {
        .new-time {
          margin-bottom: 10px;
          font-size: 20px;
        }
        .to-link-pre {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 20px;
          .new-link-tbn {
            padding: 5px;
            font-size: 12px;
            color: #df0623;
            overflow: hidden;
            white-space: nowrap;
          }

        }
      }
    }
  }

}

// 大于768
@media (min-width: $screen-sm-min) {
  // address-info page
  .addressCont {
    .subtitle {
      font-size: 12px;
    }
  }
  // company-history page
  .bgHistory {
    background-color: #f2f2f2;
  }
  .history-content {
    .history-hd {
      font-size: 36px;
    }
    .history-bd {
      margin-top: 54px;
      margin-bottom: 54px;
      .history-bd-m {
        margin: 0 24px;
        border-left: 3px solid #cd3a2a;
        .circle-top {
          left: -10px;
          width: 18px;
        }
        .circle-bottom {
          left: -10px;
          width: 18px;
        }
      }
      .history-item {
        margin-top: 80px;
      }
      .history-bd-l {
        .history-item:first-child {
          margin-top: 30px;
        }
        .history-item > .circle-mark {
          top: 7px;
          right: -32px;
          width: 12px;
        }
      }
      .history-bd-r {
        .history-item:first-child {
          margin-top: 130px;
        }
        .history-item > .circle-mark {
          top: 7px;
          left: -32px;
          width: 12px;
        }
      }
    }
  }

  // addressCont page
  .addressCont {

    .subtitle {
      font-size: 16px;
    }
    .company-item-pre {
      padding: 0;
      .company-item {
        .company-bd {
          padding-top: 10px;
          padding-bottom: 30px;
          font-size: 12px;
        }
      }
    }
  }
}

// 大于992
@media (min-width: $screen-md-min) {
  // new-page

  .new-content {
    padding: 80px 0;
    .new-title {
      font-size: 30px;
      font-weight: 700;
    }
    .new-item {
      .new-left {
        position: relative;
        padding-right: 180px;
        padding-bottom: 100px;
        border-right: 3px solid #df0623;
        h2 {
          display: block;
          margin-bottom: 15px;
          font-size: 28px;
          color: #df0623;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .new-bd {
          font-size: 14px;
          .new-tips {
            margin-bottom: 15px;
            font-size: 16px;
            font-weight: 700;
          }
        }
        .circle-tips {
          position: absolute;
          right: -11px;
          width: 20px;
          &.normal {
            top: 10px
          }
          &.first {
            top: 0
          }
          &.last {
            bottom: 0
          }
        }
      }
      .new-right {
        padding-left: 60px;
        .new-time {
          margin-bottom: 15px;
          font-size: 35px;
        }
        .to-link-pre {
          display: flex;
          flex-direction: row;
          align-items: center;
          .new-link-tbn {
            padding: 10px 5px;
            font-size: 20px;
            color: #df0623;
            overflow: hidden;
            white-space: nowrap;
          }

        }
      }
    }

  }
  // company-history page
  .history-content {
    .history-hd {
      font-size: 36px;
      margin-top: 40px;
    }
    .history-bd {
      margin-top: 54px;
      margin-bottom: 54px;
      .history-bd-m {
        margin: 0 24px;
        border-left: 3px solid #cd3a2a;
        .circle-top {
          left: -10px;
          width: 18px;
        }
        .circle-bottom {
          left: -10px;
          width: 18px;
        }
      }
      .history-item {
        margin-top: 80px;
      }
      .history-bd-l {
        .history-item:first-child {
          margin-top: 30px;
        }
        .history-item > .circle-mark {
          top: 7px;
          right: -32px;
          width: 12px;
        }
      }
      .history-bd-r {
        .history-item:first-child {
          margin-top: 130px;
        }
        .history-item > .circle-mark {
          top: 7px;
          left: -32px;
          width: 12px;
        }
      }
    }
  }

  // addressCont page
  .addressCont {
    .subtitle {
      font-size: 16px;
    }

    .company-item-pre {
      padding: 0;
      .company-item {
        .company-bd {
          padding-top: 20px;
          padding-bottom: 30px;
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }

}

// 大于1200
@media (min-width: $screen-lg-min) {
  // company-history page

  .history-content {
    .history-hd {
      font-size: 40px;
    }
    .history-bd {
      margin-top: 60px;
      margin-bottom: 60px;
      .history-bd-m {
        margin: 0 40px;
        border-left: 3px solid #cd3a2a;
        .circle-top {
          left: -11px;
          width: 18px;
        }
        .circle-bottom {
          left: -11px;
          width: 18px;
        }
      }
      .history-item {
        margin-top: 150px;
      }
      .history-bd-l {
        .history-item:first-child {
          margin-top: 30px;
        }
        .history-item > .circle-mark {
          top: 7px;
          right: -47px;
          width: 12px;
        }
      }
      .history-bd-r {
        .history-item:first-child {
          margin-top: 150px;
        }
        .history-item > .circle-mark {
          top: 7px;
          left: -47px;
          width: 12px;
        }
      }
    }
  }

  .production-details {

    .rate-line::before {
      transform: translateY(-2px);
      width: 280px;
    }
    .rate-line::after {
      transform: translateY(-2px);
      width: 24px;
      height: 36px;
    }
  }

}

// 大于1440
@media (min-width: $screen-xl-min) {
}

.new-content {
  h1 {
    display: block;
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 40px
  }
  .newCont {
    line-height: 24px;
  }
}