.footerWarp{
  &.fixed{position: fixed; width: 90%; bottom: 0;
    left: 50%; margin-left: -45%}
padding: 60px 0 40px 0;
  //height: 160px;
  background-color: #f7f8f8;
  .content{
    margin-left: 120px;
    margin-right:120px;
    border-bottom: 1px solid #707070;
    text-align: center;
    & img{width: 300px}
  }
  .copyright{display: block; text-align: center;
    padding: 0; margin: 0; font-size: 12px; color:#ababab; padding-top: 10px;}
}

.indFooter {
  width: 100%;
  background-color: #fff;
  margin: 50px 0 30px 0;
}
.indCopyright {
  text-align: center;
}


.indCopyright .crTit {
margin-bottom: 20px;
  img{width: 120px; height: 120px}
}

.indCopyright .crCode {
  color: #ababab;
  font-size: 12px;
   text-align: center;
}

@media  (max-width: $screen-sm-max) {

  .footerWarp{
    .content{
      margin-left: 40px;
      margin-right:40px;
    }

  }

}