body {
  font-family: "PingFang SC", "Microsoft YaHei", "黑体", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a {
  text-decoration: none;
  color: #000000;
}

a:hover {
  text-decoration: none;
  color: #1d1d1d;
}

.ind_p0 {
  padding: 0
}

.clearMargin {
  margin-left: 0;
  margin-right: 0;
  background-color: #a91d16;
}

.slogan-bg {

  width: 100%;
  height: calc(100vw * 0.619);
  min-height: 198px;
  align-items: center;
  color: #fff;
  background: #000 url("../images/slogan-bg.jpg") no-repeat scroll center;
  background-size: cover;
}

.honor {
  flex: 1;
  background-color: #a91d16;
  width: 100%;
  position: relative;
}

.IndHistory {
  flex: 1;
  background-color: #ffffff;
  width: 100%;
}

.FSindustry {
  flex: 1;
  background-color: #909090;
  width: 100%;
}

.FSindustry:before, .IndHistory:before {
  clear: both;
  content: "";
}

.news {
  position: relative;
  background: url('../images/news.jpg') no-repeat center right;
  background-size: cover;
  height: 100%;
  .news_pos {
    width: 100%;
    position: absolute;
    //bottom: 20px;
  }
  .indImg_md {
    min-height: 220px;
    .ImgSize {
      width: 32%;
      margin-left: 30px;
      margin-top: 130px;
    }
    @media (min-width: $screen-sm-min) {
      min-height: 360px;
      .ImgSize {
        width: 30%;
        margin-left: 50px;
        margin-top: 200px;
      }
    }
  }

}

.brand {
  position: relative;
  background: url('../images/brand.jpg') no-repeat center right;
  background-size: cover;
  height: 100%;
  .brand_pos {
    width: 100%;
    position: absolute;
    //bottom: 20px;
  }
  .indImg_md {
    min-height: 220px;
    .ImgSize {
      width: 46%;
      margin-left: 30px;
      margin-top: 120px;
    }
    @media (min-width: $screen-sm-min) {
      min-height: 360px;
      .ImgSize {
        width: 40%;
        margin-left: 50px;
        margin-top: 200px;
      }
    }
  }

}

.IndHistory {
  .indImg_md {
    min-height: 210px;
    .ImgSize {
      width: 46%;
      margin-left: 30px;
      margin-top: 120px;
    }
    @media (min-width: $screen-sm-min) {
      min-height: 360px;
      .ImgSize {
        margin-left: 50px;
        margin-top: 200px;
      }
    }
  }
}

.FSindustry {
  .indImg_md {
    min-height: 220px;
    .ImgSize {
      width: 64%;
      margin-left: 30px;
      margin-top: 100px;
    }
    @media (min-width: $screen-sm-min) {
      min-height: 360px;
      .ImgSize {
        margin-left: 50px;
        margin-top: 145px;
      }
    }
  }
}

.indexMore {
  .indImg_md {
    min-height: 220px;
    .ImgSize {
      width: 22%;
      margin-left: 30px;
      margin-top: 120px;
    }
    @media (min-width: $screen-sm-min) {
      min-height: 360px;
      .ImgSize {
        margin-left: 50px;
        margin-top: 180px;
      }
    }
  }
}

.honor {
  .indImg_md {
    min-height: 210px;
    .ImgSize {
      width: 32%;
      margin-left: 30px;
      margin-top: 120px;
    }
    @media (min-width: $screen-sm-min) {
      min-height: 360px;
      .ImgSize {
        margin-left: 50px;
        margin-top: 200px;
      }
    }
  }
}

.FMCG {
  .indImg_md {
    min-height: 220px;
    .ImgSize {
      width: 22%;
      margin-left: 30px;
      margin-top: 120px;
    }
    @media (min-width: $screen-sm-min) {
      min-height: 360px;
      .ImgSize {
        margin-left: 50px;
        margin-top: 180px;
      }
    }
  }
}

.news, .brand, .FMCG, .honor, .indexMore, .FSindustry, .IndHistory {
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .indImg_lg {
    display: none
  }
  .indImg_md {
    display: block
  }
  @media (min-width: $screen-md-min) {
    .indImg_lg {
      display: block
    }
    .indImg_md {
      display: none
    }
  }
}

.indexMore {
  background-color: #a91d16;
  img {
    width: 60%
  }
}

.RealEstate {
  position: relative;
  background: url(../images/RealEstate.jpg) no-repeat center right;
  background-size: cover;
  height: 220px;
  .REstate_pos {
    position: absolute;
    bottom: 20px;
    left: 30px;
    width: 24%;
  }
  .RENone{display: none}
  @media (min-width: $screen-sm-min) {
    height: 360px;
    .REstate_pos {
      position: absolute;
      bottom: 40px;
      width: 22%;
      left: 50px;
    }
  }
  @media (min-width: $screen-md-min) {
    position: relative;
    background: #eeeeec url(../images/RealEstate.jpg) no-repeat center right;
    background-size: cover;
    height: 100%;
    .REstate_pos {
      position: absolute;
      bottom: 30px;
      left: 30px;
      width: 12%;
    }
    .RENone{display: block;}
  }
}

.nav-footer {
  width: 100%;
}

.indexMore:hover, .honor:hover {
  background-color: rgba(169, 29, 22, 0.7);
}

.nav-footer ul {
  list-style: none;
}

.nav-footer ul li {
  float: left;
  display: inline-block;
  padding: 0 40px 0 40px;
  border-left: 1px solid #dcdddd;
  border-right: 1px solid #dcdddd;
  margin-left: -1px;
  font-size: 14px;
  letter-spacing: 6px;
  font-family: "PingFang SC", "黑体", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;

}

.business-list {
  display: flex;
  align-items: center;
  margin-top: 0;
}

.row-item-img {
  width: 100%;
  overflow: hidden;

}

/*leader.html*/
.leaderTit {
  border-bottom: 1px solid #cacbcb;
  margin-bottom: 50px;
  margin-top: 30px;
  padding-bottom: 10px;
  h1 {
    font-size: 30px;
    font-family: "PingFang SC", "黑体", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
  }
  @media (min-width: $screen-md-min) {
    h1 {
      font-size: 36px;
    }
  }
}

.leaderItem {
  display: block;
  border-radius: 10px;
  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:hover {
    -moz-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4); /* 老的 Firefox */
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4)
  }
}

.leaderShow {
  width: 100%;
}

.leaderShow ul {
  list-style: none;
  padding: 0;
  margin: 0
}

.leaderShow ul li {
  list-style: none
}

.leaderShow .name {
  font-size: 18px;
  display: block;
  color: #000;
  margin-top: 20px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.leaderShow .title {
  font-size: 12px;
  display: block;
  color: #000;
  letter-spacing: 1px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.hr {
}

.m-60 {
  margin-bottom: 60px;
}

//leader details

.pb-30 {
  padding-bottom: 30px
}

.topLine {
  border-top: 2px solid #c6c7c7;
}

.all-fluid {
  margin: 0 auto;
  width: 100%;

}

.leaderD-banner {
  text-align: center;
  background: #fff url("../images/bg-leaderBanner1.jpg") repeat-x;

}

.leaderD-h1 {
  font-size: 28px;
  font-family: "PingFang SC", "黑体", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

//Oursercivers
.ourSer {
  margin-top: 80px;
  margin-bottom: 80px;
  .img {
    margin: 0 auto;
    text-align: center;
    width: 150px
  }
  img {
    width: 100%;
  }
  span {
    display: block;
    text-align: center;
    margin: 0 auto;
  }
}

.banner-serDe {
  background-color: #f0f1f1;

  text-align: center;
  p {
    color: #525253;
    font-size: 14px;
    display: block;
    margin-top: 40px;
    padding: 40px 20px 60px 20px;
    line-height: 34px;
  }

  img {
    width: 220px;
  }

  h3 {
    color: #525253;
    display: block;
    font-size: 22px;
    letter-spacing: 2px;
  }
  @media (min-width: $screen-md-min) {
    height: 420px;
    p {
      padding: 0;
      line-height: 24px;
    }
  }
}

.banner-ourVision {
  height: 240px;
  background-color: #bb0000;
  background-size: auto 100%;
  text-align: center;
  img {
    margin-top: 80px;
    width: 90%;
  }
  @media (min-width: $screen-sm-min) {
    height: 300px;
    img {
      margin-top: 100px;
      width: 80%;
    }
  }
  @media (min-width: $screen-md-min) {
    height: 420px;
    img {
      margin-top: 140px;
      max-width: 800px;
    }
  }
  h3 {
    color: #fff;
    font-size: 50px;
    display: block;
    line-height: 450px;
  }
}

.ourCision {
  text-align: left;
  color: #4f4f4f;
  margin-top: 60px;
  padding: 0 40px;

  p, b {
    display: block;
  }
  p {
    margin-bottom: 30px;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 26px;
    text-align: justify;
  }
  b {
    margin-bottom: 10px;
    font-size: 16px;
  }
  @media (min-width: $screen-sm-min) {
    margin-top: 100px;
    padding: 0;
    text-align: center;
    p {
      text-align: center;
    }
  }
}

//works

.banner-works {
  position: relative;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  ul.banner-works-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    li {
      display: block;
    }
    li a img {
      width: 100%;
    }
  }
  .ck-prev,
  .ck-next {
    top: 50%;
    z-index: 2;
    width: 35px;
    height: 70px;
    opacity: .15;
    margin-top: -35px;
    position: absolute;
    border-radius: 3px;
    text-indent: -9999px;
    background-repeat: no-repeat;
    transition: opacity .2s linear 0s;
  }
  .ck-prev {
    left: 5px;
    background: url(../images/arrow-left.png) no-repeat center center;
  }
  .ck-next {
    right: 5px;
    background: url(../images/arrow-right.png) no-repeat center center;
  }
  .banner-worksbox {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 30;
    ul {
      height: 12px;
      padding: 0 4px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      li {
        position: static;
        float: left;
        margin: 3px 3px;
        em {
          width: 6px;
          height: 6px;
          cursor: pointer;
          background: #fff;
          border-radius: 100%;
          text-indent: -9999px;
          display: block;
        }
      }
      li.current em {
        background: #FE6500;
      }
      li em:hover {
        background: #FE6500;
      }
    }
  }

  .banner-lg{display: none}
  .banner-sm{display: block}
  @media (min-width: $screen-sm-min) {
    .banner-worksbox {
      ul {
        height: 16px;
        padding: 0 4px;
        li {
          margin: 4px 4px;
          em {
            width: 8px;
            height: 8px;
          }
        }
      }
    }
    .banner-lg{display: block}
    .banner-sm{display: none}
  }
}

.worksLabel {
  margin-top: 30px;
  @media (max-width: $screen-sm-min) {
    margin-top: 20px;
  }
  &:after {
    content: "";
    display: table;
    clear: both
  }

  a {
    display: block;
    float: left;
    font-size: 13px;
    margin-right: 40px;
    text-decoration: none;
    color: #000;
    padding-bottom: 20px;
    &.active {
      color: #d80012;
    }
  }

}
.leaderD {
  display: none;
}
.loading {
  text-align: center;
  padding-top: 13%;
  img {
    width: 240px
  }
  p {
    display: block;
    margin-top: 50px;
    color: #999;
    font-size: 18px
  }
  a {
    color: #61a7ef;
    font-size: 14px;
  }
}

.worksList {
  padding-top: 10px;
  .worksImg {
    border-radius: 6px;
    margin-bottom: 30px;
    &:hover {
      -moz-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4); /* 老的 Firefox */
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4)
    }
    .imgsize {
      overflow: hidden;
      width: 100%;
      height: 0;
      padding-bottom: 68.125%;
    }

    img {
      max-width: 100%;
      border-radius: 6px;
      //height:300px;
      //object-fit:cover
    }

    .worksTit {
      margin: 10px;
      padding-bottom: 20px;

    }
    h2 {
      margin: 0 0 5px 0;
      display: block;
      font-size: 16px;
      font-weight: 400;
      color: #000;
      letter-spacing: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    span {
      color: #999;
      display: block;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &.more {
        text-align: right;
        margin-top: 5px;
      }
    }
  }
}

.worksSubmenu {
  margin-top: 50px;
  margin-bottom: 0;
  position: relative;
  text-align: center;
  border-bottom: 0;

  .wrap {
    width: 960px;
    position: static;
    left: 50%;
    top: -25px;
    z-index: 20;
    margin-left: 0;
  }
  @media (max-width: $screen-md-min) {
    margin-top: 30px;
    overflow-x: scroll;
    width: 100%;
    .wrap {
      padding-top: 5px;
      padding-bottom: 5px;
      min-height: 60px;
    }
  }
  @media (min-width: $screen-md-min) {
    border-bottom: 1px solid #d1d2d2;
    margin-top: 80px;
    margin-bottom: 40px;
    .wrap {
      width: 960px;
      position: absolute;
      left: 50%;
      top: -25px;
      z-index: 20;
      margin-left: -480px;
    }
  }
  .SubmenuItem {
    float: left;
    font-size: 14px;
    background-color: #ffffff;
    height: 50px;
    line-height: 50px;
    border-radius: 6px;
    box-shadow: 1px 0 6px rgba(0, 0, 0, 0.3);
    width: 140px;
    margin: 0 10px 0 10px;
  }
  &:after {
    content: "";
    display: table;
    clear: both
  }
  a {
    background-color: #ffffff;
    text-decoration: none;
    color: #000;
    width: 140px;
    display: block;
    border-radius: 6px;
    &.active {
      background-color: #d80012;
      color: #ffffff;
      width: 140px;
      border-radius: 6px;
    }
  }
}

.triangle {
  content: '';
  display: block;
  color: #ffffff;
  width: 0;
  height: 0;
  background-color: transparent;
  margin-top: -14px;
  margin-left: 103%;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #000000;
  border-top: 3px solid #000000;
  transform: rotate(-135deg);
  transition: opacity .2s linear;
  &.active {
    border-left: 3px solid #f10707;
    border-top: 3px solid #f10707;
  }
}

.topBotton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: block;
  width: 80px;
  height: 80px;
  background-color: rgba(163, 163, 163, 0.9);
  border-radius: 50%;
  box-shadow: 1px 1px 12px rgba(163, 163, 163, 0.4);
  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
}

.partners {
  margin: 80px auto 60px auto;
  h1 {
    width: 100%;
    display: block;
    font-size: 34px;
    font-weight: 300;
    border-bottom: 1px solid #cacbcb;
    padding: 30px 0 10px 0;
    color: #000;
  }
  .lineBottom {
    //border-bottom: 1px solid #cacbcb;
    img {
      padding: 10px 0;
    }
  }
  .lineBottom:last-child {
    border-bottom: 0
  }
  @media (min-width: $screen-md-min) {
    margin: 20px auto 60px auto;
  }
}

.banner-Honor {
  overflow: hidden;
  img {
    width: 100%
  }

}

.ourHonor {
  color: #4f4f4f;
  line-height: 26px;
  padding: 40px;
  font-size: 14px;
  text-align: justify;
  @media (min-width: $screen-md-min) {
    padding: 0;
    line-height: 40px;
    margin-bottom: 100px;
    font-size: 15px;
  }
}

.joyoundHD {
  margin-top: 60px;
  margin-bottom: 0;
  color: #404041;
  text-align: justify;
  .logosize {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
    img {
      width: 160px;
    }
  }
  h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    display: block;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .joyoung_hd, .joyoung_blue {
    margin-top: 60px;
  }
  @media (min-width: $screen-sm-min) {
    margin-top: 80px;
    margin-bottom: 100px;
    color: #404041;
    .logosize {
      width: 100%;
      img {
        width: 200px;
      }
    }
    h1 {
      text-align: left;
      font-size: 42px;
      font-weight: 300;
      display: block;
      margin-bottom: 50px;
      margin-left: 40px;
    }
    p {
      line-height: 24px;
      font-size: 14px;
      margin-bottom: 30px;
      margin-left: 40px;
    }
    .joyoung_hd, .joyoung_blue {
      margin-top: 80px;
    }

  }
  @media (min-width: $screen-md-min) {
    margin-top: 100px;
    .joyoung_hd, .joyoung_blue {
      margin-top: 100px;
    }
  }
}

@media (min-width: $screen-md-min) {

  .row2-flex {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .row2-flex-2 {
    flex: 2;
    width: 10px;
  }

  .row2-flex-1 {
    flex: 1;
    width: 10px;
  }
  .FSindustry-flex {
    display: flex;
    flex-direction: column;
  }
  .indexMore {
    background-color: #a91d16;
    height: 100%;
    img {
      width: 100%
    }
  }

  .FMCG {
    flex: 1;
    background-color: #fff;
    width: 100%;
    position: relative;
  }

  .news:hover:after, .brand:hover:after, .RealEstate:hover:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(169, 29, 22, 0.5);
  }
  .news:hover:after {
    right: -1px;
  }
  .IndHistory:hover {
    background-color: rgba(121, 6, 0, 0.3);
  }
  .FSindustry:hover {
    background-color: rgba(121, 6, 0, 0.5);
  }

  .FMCG:hover:before, .honor:hover:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(121, 6, 0, 0.3);
  }
  .mar-120 {
    margin-top: -120px;
  }
  .mt-80 {
    margin-top: 80px;
    margin-bottom: 50px
  }
}

@media (min-width: $screen-sm-min) {
  .slogan-bg {
    height: 65vh;
    min-height: 480px;
    background: #000 url("../images/slogan-bg.jpg") no-repeat scroll center;
    background-size: cover;
  }
  .leaderD {
    display: block;
  }

}

@media (max-width: $screen-md-min) {
  .D.mt-80 {
    margin-top: 40px;
    margin-bottom: 20px
  }
  .nav-search {
    display: none;
  }
}

@media (min-width: $screen-lg-min) {
  .leaderD-banner {
    width: 100%;
    height: 400px;
    img {
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }
  .leaderD {
    padding: 0;
    width: 1200px;
    line-height: 24px
  }
  .leaderD-warp {
    margin-top: 80px;
    margin-bottom: 50px;
  }
}

@media (max-width: $screen-lg-min) {
  .leaderD-banner {
    width: 100%;
    img {
      width: auto;
      height: auto;
      min-height: 94%;
      max-width: 94%;
      margin-left: 20px;
    }
  }
  .leaderD-warp {
    padding: 40px;
    line-height: 24px
  }

}

